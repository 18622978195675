var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      staticClass: "pb-5 justify-center payments",
      attrs: { row: "", wrap: "" },
    },
    [
      _c(
        "v-flex",
        { staticClass: "px-6 payments__list" },
        [
          _vm.loadingPayments
            ? _c("v-progress-circular", {
                attrs: { size: 50, color: "primary", indeterminate: "" },
              })
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "v-form",
                _vm._l(_vm.payments, function (payment) {
                  return _c("payment-list-item", {
                    key: payment.key,
                    attrs: {
                      selectedPayments: _vm.selectedPayments,
                      payment: payment,
                      showError: _vm.showError,
                      priceWidth: _vm.priceWidth,
                    },
                    on: {
                      toggle: _vm.togglePayment,
                      updatePayment: _vm.updatePayment,
                      removeError: _vm.removeError,
                      uploadFile: _vm.uploadFile,
                      completePayment: _vm.requestPay,
                      "update:showError": function ($event) {
                        _vm.showError = $event
                      },
                      "update:show-error": function ($event) {
                        _vm.showError = $event
                      },
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }