var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      staticClass: "pa-0 justify-center align-center company-page",
      class: { "company-page__padding": !_vm.paymentDue.allPaid },
      attrs: { row: "", wrap: "" },
    },
    [
      _c("payment-countdown", {
        attrs: { paymentDue: _vm.paymentDue, company: _vm.company },
      }),
      _c(
        "v-flex",
        { staticClass: "d-flex justify-center ma-0" },
        [
          _c(
            "v-card",
            { staticClass: "card", attrs: { flat: "" } },
            [
              _c(
                "v-layout",
                {
                  staticClass:
                    "d-flex flex-column pa-0 justify-center overlay text-center",
                },
                [
                  _c("h2", {
                    staticClass: "payment-title",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("general.payment.overview-title")
                      ),
                    },
                  }),
                  _c("h3", { staticClass: "payment-subtitle" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mt-n2",
                            attrs: { medium: "", color: "#1acfda" },
                          },
                          [_vm._v("mdi-map-marker")]
                        ),
                        _vm._v(
                          " " +
                            _vm._s(_vm.projectName) +
                            " | " +
                            _vm._s(_vm.unitName)
                        ),
                      ],
                      1
                    ),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.startDate) + " - " + _vm._s(_vm.endDate)
                      ),
                    ]),
                  ]),
                  _c("p", {
                    staticClass: "payment-info body-1",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t(_vm.company + ".payment.intro")),
                    },
                  }),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "justify-center": "" } },
                    [_c("payment-list", { attrs: { company: _vm.company } })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }