var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.payment.amount > 0 && _vm.payment.paymentMethod
    ? _c(
        "v-card",
        {
          staticClass: "ma-3 my-5 pointer payment-row",
          class: {
            "selected-row": _vm.isSelected,
            "disabled-row": _vm.isDisabled,
          },
          attrs: { flat: "", disabled: _vm.isDisabled },
        },
        [
          _c(
            "v-list-item",
            {
              attrs: { "three-line": "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("toggle", _vm.payment)
                },
              },
            },
            [
              _vm.isSelected
                ? _c(
                    "div",
                    { staticClass: "check-mark-container" },
                    [
                      _c("v-img", {
                        staticClass: "check-mark",
                        attrs: {
                          alt: "checkMark",
                          src: require("@assets/icons/check-mark.svg"),
                          transition: "scale-transition",
                          width: "40px",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-item-avatar",
                { staticClass: "mr-5", attrs: { tile: "", size: "80" } },
                [
                  _c("v-img", {
                    staticClass: "item-logo pa-5",
                    attrs: {
                      src: _vm.img,
                      contain: "",
                      transition: "scale-transition",
                      width: "25px",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                { staticClass: "payment-content" },
                [
                  _c("v-list-item-title", { staticClass: "headline mb-1" }, [
                    _vm._v(" " + _vm._s(_vm.payment.title) + " "),
                  ]),
                  _c("v-list-item-subtitle", [
                    _vm._v(" " + _vm._s(_vm.payment.info) + " "),
                  ]),
                  !_vm.isDisabled
                    ? _c("div", { staticClass: "payment-price align-center" }, [
                        _c("p", { staticClass: "price" }, [
                          _vm._v(_vm._s(_vm.payment.amount)),
                        ]),
                        _c("p", [_vm._v("euro")]),
                      ])
                    : _c(
                        "div",
                        { staticClass: "payment-price-payed align-center" },
                        [
                          _vm.payment.paymentMethod != "recurring"
                            ? _c("p", { staticClass: "text-uppercase" }, [
                                _vm._v(_vm._s(_vm.$t("general.payment.payed"))),
                              ])
                            : _c("p", { staticClass: "text-uppercase" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("general.payment.payedRecurring")
                                  )
                                ),
                              ]),
                        ]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }