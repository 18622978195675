var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "max-width": "45rem", width: "100%" } },
    [
      !_vm.loadingPayments
        ? _c(
            "v-flex",
            { staticClass: "d-flex flex-column mt-15 upload-container" },
            [
              _c("v-layout", { staticClass: "upload-container__content" }, [
                _c(
                  "div",
                  { staticClass: "upload-container__content__item mr-4" },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "d-flex justify-center",
                        attrs: { disabled: _vm.imageCheck.front },
                        on: {
                          click: function ($event) {
                            _vm.dialog = true
                            _vm.id = "front"
                          },
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn--transform btn--upload pa-6",
                            attrs: { elevation: "0" },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: { medium: "", left: "", color: "white" },
                              },
                              [_vm._v("mdi-upload")]
                            ),
                            _c("p", {
                              staticClass: "ma-0 white--text",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "general.payment-user-info.passport-front"
                                  )
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "upload-container__content__item" },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "d-flex justify-center",
                        attrs: { disabled: _vm.imageCheck.back },
                        on: {
                          click: function ($event) {
                            _vm.dialog = true
                            _vm.id = "back"
                          },
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn--transform btn--upload pa-6",
                            attrs: { elevation: "0" },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: { medium: "", left: "", color: "white" },
                              },
                              [_vm._v("mdi-upload")]
                            ),
                            _c("p", {
                              staticClass: "ma-0 white--text",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "general.payment-user-info.passport-back"
                                  )
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "pr-3 upload-container__content__check" },
                [
                  _c("v-slide-y-transition", [
                    _vm.imageCheck.front
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "upload-container__confirmation pa-4 px-6 mt-4",
                            attrs: { elevation: "2" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("p", {
                                  staticClass: "body-1 ma-0 white--text",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "general.payment-user-info.front-check"
                                      )
                                    ),
                                  },
                                }),
                                _c("v-slide-y-transition", [
                                  _vm.iconCheck.front
                                    ? _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color:
                                                      "blue-grey lighten-3",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-close-circle-outline"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "remove-text body-1 ma-0 ml-1 mr-6 grey--text text--lighten-1",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeImage(
                                                        "front"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("u", [
                                                    _c("i", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "general.payment-user-info.remove"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                medium: "",
                                                left: "",
                                                color: "green accent-2",
                                              },
                                            },
                                            [_vm._v("mdi-check-circle")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            ),
                            _c("v-progress-linear", {
                              class: _vm.tempSpacing.front,
                              attrs: {
                                active: _vm.loading.front,
                                color: _vm.progressColor,
                                indeterminate: "",
                                rounded: "",
                                height: "4",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("v-slide-y-transition", [
                    _vm.imageCheck.back
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "upload-container__confirmation pa-4 px-6 mt-2",
                            attrs: { elevation: "2" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("p", {
                                  staticClass: "body-1 ma-0 white--text",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "general.payment-user-info.back-check"
                                      )
                                    ),
                                  },
                                }),
                                _c("v-slide-y-transition", [
                                  _vm.iconCheck.back
                                    ? _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color:
                                                      "blue-grey lighten-3",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-close-circle-outline"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "remove-text body-1 ma-0 ml-1 mr-6 grey--text text--lighten-1",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeImage(
                                                        "back"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("u", [
                                                    _c("i", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "general.payment-user-info.remove"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                medium: "",
                                                left: "",
                                                color: "green accent-2",
                                              },
                                            },
                                            [_vm._v("mdi-check-circle")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            ),
                            _c("v-progress-linear", {
                              class: _vm.tempSpacing.back,
                              attrs: {
                                active: _vm.loading.back,
                                color: _vm.progressColor,
                                indeterminate: "",
                                rounded: "",
                                height: "4",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-flex",
        { staticClass: "d-flex py-4 mt-3" },
        [
          _c(
            "v-btn",
            {
              staticClass: "btn--upload btn--transform white--text",
              attrs: { outlined: "", large: "", disabled: !_vm.valid },
              on: { click: _vm.goToPayment },
            },
            [_vm._v(_vm._s(_vm.$t("general.payment-user-info.confirm")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _vm.dialog
            ? _c("picture-uploader", {
                on: {
                  uploadAvatar: function ($event) {
                    _vm.onUploadAvatar($event)
                    _vm.dialog = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }